import { createContext, FC, ReactNode, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { masterApi } from "../lib/api/masterApi";
import { errorToast } from "../utils/toast";

interface countriesArray {
  image: ReactNode;
  code3: ReactNode | string;
  flag: string;
  dial_code: string;
  id: 1;
  name: string;
  num_code?: number;
  phone_code?: number;
  short_code?: string;
}
interface masterapiData {
  countries: Array<countriesArray>;
}

interface State {
  masterData: masterapiData;
}

interface AuthContextValue extends State {
  platform: "JWT";
  setMasterData: (data: masterapiData) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type SetMasterDataFromApi = {
  type: "SET_MASTER_DATA";
  payload: masterapiData;
};

type Action = SetMasterDataFromApi;

const initialState: State = {
  masterData: {
    countries: [],
  },
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  SET_MASTER_DATA: (state: State, action: SetMasterDataFromApi): State => {
    const updatedMasterData = { ...state.masterData, ...action.payload };
    return {
      ...state,
      masterData: updatedMasterData,
    };
  },
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: "JWT",
  setMasterData: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const masterDatAPIcALL = async () => {
    const masterDataRes = await masterApi.countryApi();
    if (masterDataRes?.status === 200) {
      dispatch({ type: "SET_MASTER_DATA", payload: masterDataRes?.data?.data });
    } else {
      errorToast(masterDataRes?.message ?? "");
    }
  };

  useEffect(() => {
    masterDatAPIcALL();
  }, []);

  const setMasterData = (payload: any) => {
    dispatch({ type: "SET_MASTER_DATA", payload });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "JWT",
        setMasterData,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
