import { createSlice } from "@reduxjs/toolkit";

export interface IChargerMasterState {
  master: any;
}

const initialState: IChargerMasterState = {
  master: {},
};

const ChargerMasterSlice = createSlice({
  name: "charger_master",
  initialState,
  reducers: {
    getChargerMasterData: (state, action) => ({
      ...state,
      master: {
        ...state.master,
        ...action.payload,
      },
    }),
  },
});

export const { getChargerMasterData } = ChargerMasterSlice.actions;
export default ChargerMasterSlice;
