/* eslint class-methods-use-this: "off" */

import axios from "axios";
import { clearStorage } from "../utils/general";

let interceptor: any = null;
const baseURL = "https://instg.intuthings.com/v1/api";

class Interceptors {
  constructor() {
    // Add a request interceptor
    // axios.interceptors.request.use(
    //   (config) => {
    //     // const [token] = readCookie();

    //     // try {
    //     //   if (token) {
    //     //     const cTime = new Date().getTime();
    //     //     sessionTime = JSON.parse(sessionTime)["sessionTime"];

    //     //     if (typeof sessionTime == "string") {
    //     //       sessionTime = new Date(sessionTime).getTime();
    //     //     }
    //     //     if (cTime < sessionTime) {
    //     //       const st = JSON.stringify({
    //     //         sessionTime: addMinutes(new Date(), 15),
    //     //       });
    //     //       setCookie(token, refreshToken, st);
    //     //     } else {
    //     //       errorToast("Session Expired, Please Login Again!");
    //     //       clearStorage();
    //     //       // window.location.href = "/";
    //     //     }
    //     //   }
    //     // } catch (e) {
    //     //   clearStorage();
    //     //   window.location.href = "/";
    //     // }

    //     // if (config.headers) {
    //     //   if (token) config.headers.authorization = `Bearer ${token}`;
    //     //   config.headers["Access-Control-Expose-Headers"] = `*`;
    //     // }
    //     return config;
    //   },
    //   (error) => Promise.reject(error),
    // );

    // Add a response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          clearStorage();
          window.location.href = "/";
        }
        return Promise.reject(error);
      },
    );
  }

  GET(url: string | number) {
    return axios
      .get(`${baseURL && baseURL}${url}`)
      .then((response) => response)
      .catch((error) => error.response);
  }

  GETFILE(url: string | number) {
    return axios
      .get(`${baseURL && baseURL}${url}`, {
        responseType: "blob",
        headers: {
          "Access-Control-Expose-Headers": "*",
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  POST(url: string | number, data: any) {
    return axios
      .post(`${baseURL && baseURL}${url}`, data)
      .then((response) => response)
      .catch((error) => error.response);
  }

  PUT(url: string | number, data: any) {
    return axios
      .put(`${baseURL && baseURL}${url}`, data)
      .then((response) => response)
      .catch((error) => error.response);
  }

  DELETEWITHDATA(url: string | number, data: any) {
    return axios
      .delete(`${baseURL && baseURL}${url}`, { data })
      .then((response) => response)
      .catch((error) => error.response);
  }

  DELETE(url: string | number) {
    return axios
      .delete(`${baseURL && baseURL}${url}`)
      .then((response) => response)
      .catch((error) => error.response);
  }

  PATCH(url: string | number, data: any) {
    return axios
      .patch(`${baseURL && baseURL}${url}`, data)
      .then((response) => response)
      .catch((error) => error.response);
  }

  GETPARAMS(url: string | number, parames: any) {
    return axios(`${baseURL && baseURL}${url}`, {
      params: { ...parames },
    })
      .then((response) => response)
      .catch((error) => error.response);
  }
}
export const interceptorFunction = () => {
  if (interceptor === null) {
    interceptor = new Interceptors();
  }
  return interceptor;
};
export default Interceptors;
