import { createSlice } from "@reduxjs/toolkit";

export interface IFleetDetailState {
  station: any;
}

const initialState: IFleetDetailState = {
  station: {},
};

const StationDetailsSlice = createSlice({
  name: "stationDetails",
  initialState,
  reducers: {
    getStationDetails: (state, action) => ({
      ...state,
      station: {
        ...state.station,
        ...action.payload,
      },
    }),
    removeStationDetails: (state) => ({
      ...state,
      station: {},
    }),
  },
});

export const { getStationDetails } = StationDetailsSlice.actions;
export default StationDetailsSlice;
