import Cookies from "js-cookie";

export const readCookie = () => {
  const token = Cookies.get("token");
  const refreshToken = Cookies.get("refreshToken");
  const sessionTime: any = Cookies.get("sessionTime");

  return [token || "", refreshToken || "", sessionTime || ""];
};

export const setCookie = (
  token: string,
  refreshToken: string,
  sessionTime?: string | number | any,
) => {
  Cookies.set("token", token);
  Cookies.set("refreshToken", refreshToken);
  if (sessionTime) {
    Cookies.set("sessionTime", sessionTime);
  }
};

export const clearStorage = () => {
  Cookies.remove("token");
  Cookies.remove("refreshToken");
  Cookies.remove("sessionTime");
};

export const getToken = () => {
  const token = Cookies.get("token");
  return token ?? "";
};

export const jsonToURI = (json: object) => encodeURIComponent(JSON.stringify(json));

export const uriToJSON = (uri: string) => JSON.parse(decodeURIComponent(uri));

export const getTariffName = (gridData: any, id: number): Record<string, any> =>
  gridData?.find((data: any) => data.id === id);

export const getBrandName = (listData: any, brand: string): Record<string, any> =>
  listData?.find((data: any) => data.value === brand);

export const getCountry = (countries: any, id: number): Record<string, any> =>
  countries?.find((country: any) => country.id === id);

export const getCountryName = (countries: any, name: string): Record<string, any> =>
  countries?.find((country: any) => country.name.toLowerCase() === name.toLowerCase());

export const getState = (countries: any, id: number): Record<string, any> => {
  let stateObj = {};
  /* eslint array-callback-return: "off" */
  countries?.find((country: any) => {
    /* eslint array-callback-return: "off" */
    country?.states?.find((state: any) => {
      if (state.gid === id) {
        stateObj = state;
      }
    });
  });
  return stateObj;
};

export const truncate = (str: string, length: number) => {
  const truncatedStr = str.substring(0, length);
  return `${truncatedStr}...`;
};

export const getStateName = (countries: any, name: string): Record<string, any> => {
  let stateObj = {};
  /* eslint array-callback-return: "off" */
  countries?.find((country: any) => {
    /* eslint array-callback-return: "off" */
    country?.states?.find((state: any) => {
      if (state.name === name) {
        stateObj = state;
      }
    });
  });
  return stateObj;
};

export const getVehicleTypeObj = (vehicleTypes: any[], value: string): Record<string, any> =>
  vehicleTypes?.find((vehicleType: any) => vehicleType.value === value);

export const getPlugTypeObj = (plugTypes: any[], value: string): Record<string, any> =>
  plugTypes?.find((plugType: any) => plugType.value === value);
