export enum ROLE_STATUS {
  INACTIVE = 0,
  ACTIVE = 1,
}

export enum ROLE_SHORT_CODE {
  SUPER_ADMIN = "SA",
  SUPER_MANAGER = "SM",
  SUPER_EMPLOYEE = "SE",

  FLEET_ADMIN = "FA",
  FLEET_MANAGER = "FM",
  FLEET_EMPLOYEE = "FE",

  STATION_ADMIN = "STA",
  STATION_MANAGER = "STM",
  STATION_EMPLOYEE = "STE",
  STATION_INSTALLER = "STI",

  ORGANIZATION_ADMIN = "OA",
  ORGANIZATION_MANAGER = "OM",
  ORGANIZATION_EMPLOYEE = "OE",

  DRIVER = "DR",
}

// export enum STATION_ROLE_CODE {
//   STA = "Station Admin",
//   STM = "Station Manager",
//   STE = "Station Employee",
//   STI = "Station Installer",
// }

export const STATION_ROLE_CODE: Record<string, string> = {
  // Admin: "STA",
  // Manager: "STM",
  Employee: "STE",
  Installer: "STI",
};

export const ORGANIZATION_ROLE_CODE: Record<string, string> = {
  Admin: "OA",
  Manager: "OM",
  Employee: "OE",
};

export const STATION_STATUS: Record<string, number> = {
  // Inactive: 0,
  Active: 1,
  "Under Maintenance": 2,
  "All Reserved": 3,
  Closed: 4,
};

export const PLUG_STATUS: Record<string, number> = {
  Offline: 0,
  Online: 1,
  Reserved: 2,
};

export enum FLEET_INITIAL_SETUP {
  NOT_COMPLETED = 0,
  COMPLETED = 1,
}

export enum FLEET_SETUP_STEP {
  NOT_STARTED = 0,
  ORG_SETUP = 1,
  STATION_CREATED = 2.1,
  STATION_TIMINGS_UPDATED = 2.2,
  STATION_OWNER_ADDED = 2.3,
  STATION_CONFIG_UPDATED = 2.4,
  CHARGER_ADDED = 3,
  STATION_SETUP_GUIDE = 4,
}

export const CHARGER_ATTRIBUTES: Record<string, string> = {
  vehicle_type: "Vehicle Type",
  brand: "Brand",
  charger_model: "Charger Model",
  maximum_output_power: "Maximum Output power",
  maximum_output_current: "Maximum output current",
  input_voltage: "Input Voltage",
  authentication: "Authentication",
  charging_standard: "Charging standard",
  connector_soket_type: "Connector/Socket Type",
  communication_protocol: "Communication Protocol",
  protection: "Protection",
  charging_mode: "Charging Mode",
  number_of_output: "Number of output",
  network_connection: "Network connection",
};

export const CHARGER_STATUS: Record<string, number> = {
  Inactive: 0,
  Active: 1,
  "Under Maintenance": 2,
  Closed: 3,
};
