import { createSlice } from "@reduxjs/toolkit";
import { FLEET_SETUP_STEP } from "../../utils/status";

export interface IConfigDetailState {
  latestStep: number;
  stationId: string;
}

const initialState: IConfigDetailState = {
  latestStep: FLEET_SETUP_STEP.NOT_STARTED,
  stationId: "",
};

const ConfigDetailsSlice = createSlice({
  name: "configDetails",
  initialState,
  reducers: {
    updateStep: (state, action) => ({
      ...state,
      latestStep: action.payload,
    }),
    setStationId: (state, action) => ({
      ...state,
      stationId: action.payload,
    }),
  },
});

export const { updateStep, setStationId } = ConfigDetailsSlice.actions;
export default ConfigDetailsSlice;
