import Gleap from "gleap";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useEffect } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import "tailwindcss/tailwind.css";
import { AuthProvider } from "../context/masterData";
import { persistor, store } from "../Store/Store";
import "../styles/globals.scss";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
    mutations: {
      retry: 0,
    },
  },
});

function App({ Component, pageProps }: AppProps) {
  // Gleap
  useEffect(() => {
    // Run within useEffect to execute this code on the frontend.
    Gleap.initialize("zljNbf1RnaPhNUxVOOOTXhHBcvytKaMF");
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Head>
            <title>IntuCharge</title>
            <meta name="viewport" content="initial-scale=1.0, width=device-width" />
          </Head>
          <AuthProvider>
            <Component {...pageProps} />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
          </AuthProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
