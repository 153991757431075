import { errorToast } from "../../utils/toast";
import { interceptorFunction } from "../interceptors";

const interceptores = interceptorFunction();

class MasterApi {
  async countryApi() {
    try {
      return await interceptores.GET("/master/countries");
    } catch (e: any) {
      errorToast(e.response ? e.response?.data?.message : "Error In getting Data");
      return e;
    }
  }
}

export const masterApi = new MasterApi();
